import React from 'react';
import "./Podcast.css"
import MainLayout from "../../layouts/MainLayout/MainLayout";
import {VideoPlayer} from "../../components/VideoPlayer/VideoPlayer";

function Podcast() {
    return (
        <MainLayout>
            <h2 className="podcast__title">
                PODCAST
            </h2>
            <div className="podcast__container">
                <VideoPlayer
                    url="https://canil-bucket.s3.amazonaws.com/videos/podcast_compressed.mp4"
                    title="Podcast el canil"
                />
            </div>
        </MainLayout>
    );
}

export default Podcast;