import React from 'react';
import './StoreCard.css';
import Button from "../Button/Button";

function StoreCard(props) {
    const { imgUrl, name, price } = props;

    const handleBuy = () => {
        const phoneNumber = "5493512047534";
        const message = `¡Hola, buenos días! Me gustaría comprar ${name} a un precio de $${price}. ¡Gracias!`;
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      
        window.open(url, '_blank');
    };

    return (
        <div className="storeCard__container" {...props}>
            <img src={imgUrl} className="storeCard__image" alt="product"/>
            <h3 className="storeCard__title">{name.toUpperCase()}</h3>
            <p className="storeCard__price">$ {price}</p>
            <Button type="button" onClick={handleBuy}>COMPRAR</Button>
        </div>
    );
}

export default StoreCard;