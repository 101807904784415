import React from 'react';
import './Button.css';

function Button(props) {
    const {children, variant = "primary", ...others} = props;
    return (
        <button className={`button__${variant}`} {...others}>{children}</button>
    );
}

export default Button;