import React from "react";
import "./HowIWork.css";
import ComoTrabajo from "../../../assets/images/como_trabajo.png";

function HowIWork() {
    return (
        <div className="howIWork__container">
            <img
                src={ComoTrabajo}
                className="howIWork__image"
                alt="como_trabajo"
            />
            <p className="howIWork__text">
                Cada perro es un universo único y un ser vivo con una identidad
                propia, por lo cual el proceso de educación / adiestramiento no
                debe interferir con su personalidad, sino ayudar a cada tutor o
                familia a entenderlo como un{" "}
                <span>
                    individuo completo, con emociones, un lenguaje y necesidades
                    propias.
                </span>
            </p>
        </div>
    );
}

export default HowIWork;
