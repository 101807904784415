import React from 'react';
import './BrochureDownload.css';
import DemoImage from '../../assets/images/demo_img.png';

function BrochureDownload() {
    return (
        <div className="brochureDownload__container">
            <img src={DemoImage} alt="brochure" className="brochureDownload__image"/>
            <h3 className="brochureDownload__title">DESCARGAR BROCHURE</h3>
        </div>
    );
}

export default BrochureDownload;