import React, {useEffect, useState} from 'react';
import './Store.css';
import StoreCard from "../../components/StoreCard/StoreCard";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import IconArrow from "../../assets/images/icon_arrow.png";
import axios from "axios";

function Store() {
    const [filter, setFilter] = useState(null);
    const [cards, setCards] = useState(null);
    const [displayCards, setDisplayCards] = useState(null);

    const getClassName = (_elementFilter) => {
        if (filter === _elementFilter) {
            return "store__filters__text--active";
        } else {
            return "store__filters__text";
        }
    }

    const init = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_SERVER}/api/web/products`
            );
            setCards(response.data.data)
            setDisplayCards(response.data.data)
        } catch (error) {
            alert("Ha ocurrido un error. Por favor, intente nuevamente más tarde")
        }
    }

    useEffect(() => {
        if (cards && filter) {
            const filteredCards = cards.filter(card => card.category === filter);
            setDisplayCards(filteredCards);
        } else {
            setDisplayCards(cards)
        }
    }, [filter]);

    useEffect(() => {
        init()
    }, [])

    return (
        <MainLayout>
            <div className="store__title__container">
                <h1 className="store__title">
                    TIENDA <br/>
                    TIENDA.TIENDA <br/>
                    <span className="store__title--mobile">TIENDA.TIENDA.TIENDA <br/></span>
                </h1>
            </div>
            <div className="store__filters__container">
                <p className={getClassName('juguete')}
                   onClick={() => {setFilter('TOYS')}}
                >
                    <img src={IconArrow} alt="arrow" className="store__filters__arrow"/>JUGUETES
                </p>
                <p className={getClassName('paseo')}
                   onClick={() => {setFilter('WALKING')}}
                >
                    <img src={IconArrow} alt="arrow" className="store__filters__arrow"/>ARTÍCULOS DE PASEO
                </p>
                <p className={getClassName('snack')}
                   onClick={() => {setFilter('SNACKS')}}
                >
                    <img src={IconArrow} alt="arrow" className="store__filters__arrow"/>SNACKS
                </p>
                <p className={getClassName('')}
                   onClick={() => {setFilter(null)}}
                >
                    <span>ELIMINAR FILTROS</span>
                </p>

            </div>
            <div className="store__cards">
                {
                    cards
                        ? displayCards.map((card, index) => {
                            return (
                                <StoreCard
                                    key={index}
                                    imgUrl={card.imageURL}
                                    name={card.name}
                                    price={card.price}
                                />
                            )
                        })
                        : <p>Cargando...</p>
                }
            </div>
        </MainLayout>
    );
}

export default Store;