import React from 'react';
import './LandingForm.css';
import FormItem from "../../../components/FormItem/FormItem";
import {useForm} from "@mantine/form";
import Button from "../../../components/Button/Button";
import axios from "axios";

function LandingForm() {
    const form = useForm({
        initialValues: {
            nombre: '',
            email: '',
            telefono: '',
            pais: '',
            mensaje: '',
        },
    });

    const handleSubmit = async (values) => {
        const data = {...values}

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/sendEmail`, {data})
            alert("Mensaje enviado con exito")
            form.reset()
        } catch (error) {
            alert(error)
        }
    }

    return (
        <div className="landingForm__container">
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                <FormItem
                    name="nombre"
                    placeholder="Nombre y apellido"
                    required
                    form={form}
                />
                <FormItem
                    name="email"
                    placeholder="Email"
                    required
                    form={form}
                />
                <FormItem
                    name="telefono"
                    placeholder="Telefono"
                    required
                    form={form}
                />
                <FormItem
                    name="pais"
                    placeholder="Pais y provincia de residencia"
                    required
                    form={form}
                />
                <FormItem
                    name="mensaje"
                    placeholder="Mensaje"
                    required
                    form={form}
                />

                <Button type="submit">Enviar</Button>
            </form>
        </div>
    );
}

export default LandingForm;