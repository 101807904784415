import React from 'react';
import './EducationAlternativeItem.css';
import IconArrow from '../../assets/images/icon_arrow.png'

function EducationAlternativeItem(props) {
    const {title, subtitle, text} = props;
    return (
        <div className="educationAlternativeItem__container">
            <div className="educationAlternativeItem__title__container">
                <img src={IconArrow} alt="arrow" className="educationAlternativeItem__icon"/>
                <h3 className="educationAlternativeItem__title">{title}</h3>
            </div>
            <h4 className="educationAlternativeItem__subtitle">{subtitle}</h4>
            <p className="educationAlternativeItem__text">{text}</p>
        </div>
    );
}

export default EducationAlternativeItem;