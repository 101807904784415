import React from "react";
import "./EducationDescription.css";
import LandingHeroImage from "../../../assets/images/landing_hero.png";
import HeroDetail from "../../../assets/images/hero_detail.png";

function EducationDescription() {
    return (
        <div className="educationDescription__container">
            <div className="educationDescription__column">
                <h1 className="educationDescription__title">
                    EDUCACIÓN CANINA CONSCIENTE
                </h1>
                <div className="educationDescription__column__content">
                    <img
                        src={HeroDetail}
                        alt="detail"
                        className="educationDescription__column__detail"
                    />
                    <p className="educationDescription__column__text">
                        Los perros han desarrollado una relación excepcional con
                        los humanos y, en este sentido, pueden considerarse la
                        única especie que se ha establecido un nicho propio en
                        nuestra sociedad.
                    </p>
                </div>
            </div>
            <div className="educationDescription__image__container">
                <img
                    className="educationDescription__image"
                    src={LandingHeroImage}
                    alt="elcanil_hero"
                />
            </div>
        </div>
    );
}

export default EducationDescription;
