import React from 'react';
import {TextInput} from '@mantine/core';
import './FormItem.css';

function FormItem(props) {
    const {name, placeholder, required, form} = props;
    return (
        <TextInput
            withAsterisk
            placeholder={placeholder}
            required={required}
            {...form.getInputProps(name)}
            classNames={{
                wrapper: 'textInput__wrapper',
                input: 'textInput__input',
            }}
        />
    );
}

export default FormItem;