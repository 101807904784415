import React from 'react';
import './AboutPhilosophy.css';
import MisionImage from '../../../assets/images/image_mision.png';
import VisionImage from '../../../assets/images/image_vision.png';
import PhilosophyItem from "../../../components/PhilosophyItem/PhilosophyItem";

function AboutPhilosophy() {
    return (
        <div className="aboutPhilosophy__container">
            <PhilosophyItem
                image={MisionImage}
                direction="right"
            />
            <PhilosophyItem
                image={VisionImage}
                direction="left"
            />
        </div>
    );
}

export default AboutPhilosophy;