import { Routes, Route, BrowserRouter } from 'react-router-dom';
import {Landing} from "../pages/Landing/Landing";
import {About} from "../pages/About/About";
import Services from "../pages/Services/Services";
import Store from "../pages/Store/Store";
import Academy from "../pages/Academy/Academy";
import Contact from "../pages/Contact/Contact";
import Blog from "../pages/Blog/Blog";
import Podcast from "../pages/Podcast/Podcast";
import Daycare from "../pages/Daycare/Daycare";

const AppRoutes = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route exact path="/nosotros" element={<About />} />
                    <Route exact path="/guarderia" element={<Daycare />} />
                    <Route exact path="/academia" element={<Academy />} />
                    <Route exact path="/servicios/:tipo" element={<Services />} />
                    <Route exact path="/tienda" element={<Store />} />
                    <Route exact path="/blog" element={<Blog />} />
                    <Route exact path="/podcast" element={<Podcast />} />
                    {/*<Route exact path="/contacto" element={<Contact />} />*/}
                </Routes>
            </BrowserRouter>
        </>
    )
}
export default AppRoutes