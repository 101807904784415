import React from "react";
import "./LandingAbout.css";
import LandingDog from "../../../assets/images/landing_dog.png";
import Invitation from "../../../assets/images/teinvitoaserparte.png";
import Invitation2 from "../../../assets/images/teinvitoaserparte2.png";

function LandingAbout() {
    return (
        <div className="landingAbout__container">
            <img src={LandingDog} alt="perro" className="landingAbout__image" />
            <div className="landingAbout__block">
                <img
                    src={Invitation}
                    alt="msg"
                    className="landingAbout__image__invitation"
                />
                <img
                    src={Invitation2}
                    alt="msg"
                    className="landingAbout__image__invitation--mobile"
                />
                <p className="landingAbout__text">
                    EL CANIL, es una ESCUELA MULTIESPECIE fundada por Diego
                    Gavarrete.
                    <br />
                    Nuestro sueño es que el mundo sea un lugar más consciente y
                    respetuoso con los perros.
                    <br />
                    <br />
                    En el corazón de nuestra filosofía de educación canina se
                    encuentra el entender que ellos son seres sensibles con sus
                    propias emociones y necesidades. En nuestras manos está el
                    brindarles una mejor calidad de vida.
                    <br />
                    <br />
                    ¿Qué tan dispuesto estás a aportar tu granito de arena para
                    que el cambio efectivamente suceda?
                </p>
            </div>
        </div>
    );
}

export default LandingAbout;
