import React, {useEffect, useRef} from 'react';
import './About.css';
import {VideoPlayer} from "../../components/VideoPlayer/VideoPlayer";
import AboutIntroduction from "../../features/About/AboutIntroduction/AboutIntroduction";
import AboutPhilosophy from "../../features/About/AboutPhilosophy/AboutPhilosophy";
import HowIWork from "../../features/About/HowIWork/HowIWork";
import AboutWillLearn from "../../features/About/AboutWillLearn/AboutWillLearn";
import Button from "../../components/Button/Button";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import {useLocation, useNavigate} from "react-router-dom";

export const About = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({behavior: 'smooth'})

    useEffect(() => {
        if (location?.state?.to === "filosofia") {
            executeScroll();
        }
    }, [location.state]);

    return (
        <MainLayout>
            <section className="about__videoPlayer">
                <VideoPlayer
                    url="https://canil-bucket.s3.amazonaws.com/videos/about_compressed.mp4"
                    title="Sobre nosotros"
                />
            </section>

            <section className="about__introduction">
                <AboutIntroduction/>
            </section>

            <section className="about__philosophy" ref={myRef}>
                <AboutPhilosophy/>
            </section>

            <section className="about__howIWork">
                <HowIWork/>
            </section>

            {/*<section className="about__enhancedMenu">*/}
            {/*    <AboutEnhancedMenu/>*/}
            {/*</section>*/}

            <section className="about__willLearn">
                <AboutWillLearn/>
            </section>

            <div className="about__button">
                <Button variant="secondary" onClick={() => navigate("/", {state: {to: "contacto"}})}>
                    ¡QUIERO SER GUÍA Y REFERENTE DE MI PERRO!
                </Button>
            </div>
        </MainLayout>
    )
}
