import React from 'react';
import './LandingMessage.css';
import LandingDogWhite from "../../../assets/images/landing_dog_white.png";
import LandingMessageImg from "../../../assets/images/landing_message.png";

function LandingMessage() {
    return (
        <div className="landingMessage__container">
            <img src={LandingDogWhite} alt="dog" className="landingMessage__dog"/>
            <img src={LandingMessageImg} alt="message" className="landingMessage__message"/>
        </div>
    );
}

export default LandingMessage;