import React from "react";
import "./DiagnosisInterview.css";

function DiagnosisInterview() {
    return (
        <div className="diagnosisInterview__container">
            <h2 className="diagnosisInterview__title">
                <span>EDUCACIÓN</span> <br /> CANINA
            </h2>
            <p className="diagnosisInterview__text">
                Como primera instancia antes de ingresar a la escuela, se
                realiza una entrevista online con el objetivo de conocernos y
                brindar un mejor asesoramiento a cada tutor y/o familia que se
                acerca buscando mejorar en su calidad de vida junto a su perro.
                <br />
                <br />
                Así se podrá conocer mejor las necesidades del perro y brindar
                una evaluación sobre la situación que se atraviesa para
                contemplar a sí mismo la viabilidad del tratamiento.
                <br />
                <br />
                <span>
                    La entrevista NO tiene como objetivo brindar tips o consejos
                    para comenzar a trabajar, es una charla que trata de abrir
                    el panorama a las familias.
                </span>
            </p>
        </div>
    );
}

export default DiagnosisInterview;
