import React from 'react';
import './Footer.css';
import Logo from '../../assets/images/logo_white.png';
import FbIcon from '../../assets/images/icon_fb.png';
import IgIcon from '../../assets/images/icon_ig.png';
import WappIcon from '../../assets/images/icon_wapp.png';

function Footer() {
    return (
        <div className="footer__wrapper">
            <div className="footer__container">
                <img src={Logo} alt="el_canil" className="footer__logo"/>
                <div className="footer__socialMedia">
                    <img src={FbIcon} alt="facebook" className="footer__icon"/>
                    <img src={IgIcon} alt="instagram" className="footer__icon"/>
                    <img src={WappIcon} alt="whatsapp" className="footer__icon"/>
                </div>
            </div>
        </div>
    );
}

export default Footer;