import React, {useEffect, useRef} from 'react';
import './Landing.css';
import {VideoPlayer} from "../../components/VideoPlayer/VideoPlayer";
import EducationDescription from "../../features/Landing/EducationDescription/EducationDescription";
import LandingTextBanner from "../../assets/images/landing_banner.png"
import LandingAbout from "../../features/Landing/LandingAbout/LandingAbout";
import LandingEnhancedMenu from "../../features/Landing/LandingEnhancedMenu/LandingEnhancedMenu";
import LandingTestimonial from "../../features/Landing/LandingTestimonial/LandingTestimonial";
import LandingMessage from "../../features/Landing/LandingMessage/LandingMessage";
import LandingForm from "../../features/Landing/LandingForm/LandingForm";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import LoQueDicenDeNosotros from "../../assets/images/lo_que_dice_de_nosotros.png"
import {useLocation} from "react-router-dom";

export const Landing = () => {
    const location = useLocation();
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({behavior: 'smooth'})

    useEffect(() => {
        if (location?.state?.to === "contacto") {
            executeScroll();
        }
    }, [location.state]);

    return (
        <MainLayout>
            <VideoPlayer
                url="https://canil-bucket.s3.amazonaws.com/videos/home_compressed.mp4"
                title="Presentación el canil"
            />
            <EducationDescription/>
            <img src={LandingTextBanner} className="landing__banner" alt="banner"/>

            <LandingAbout/>
            <LandingEnhancedMenu/>

            <section className="landing__testimonial">
                <img className="landing__testimonial__image" src={LoQueDicenDeNosotros} alt="banner"/>
                <LandingTestimonial/>
            </section>

            <section className="landing__message" ref={myRef}>
                <LandingMessage/>
            </section>
            <section className="landing__form">
                <LandingForm/>
            </section>
        </MainLayout>
    )
}
