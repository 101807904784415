import React from 'react';
import './PhilosophyItem.css';
import VisionArrow from "../../assets/images/vision_arrow.png";
import MisionArrow from "../../assets/images/mision_arrow.png";
import MisionTitle from "../../assets/images/mision_text.png";
import VisionTitle from "../../assets/images/vision_text.png";

const getStyle = (_dir) => {
    if (_dir === "left") {
        return ({
            flexDirection: "row-reverse"
        })
    } else {
        return ({
            flexDirection: "row"
        })
    }
}
const getImg = (_dir, _isMobile) => {
    if (_dir === "left") {
        return <img className="philosophyItem__vision" src={VisionArrow} alt="vision"/>

    } else {
        return <img className="philosophyItem__mision" src={MisionArrow} alt="vision"/>
    }
}

const getTitle = (_dir) => {
    if (_dir === "left") {
        return <img className="philosophyItem__title" src={VisionTitle} alt="vision" style={{maxWidth: "65%", alignSelf: "flex-start"}}/>
    } else {
        return <img className="philosophyItem__title" src={MisionTitle} alt="msion"/>
    }
}

const newGetTitle = (_dir) => {
    if (_dir !== "left") {
        return <span className="philosophyItem__text">Fortalecer el respeto y la empatía hacia las necesidades de nuestros amigos
        caninos, tanto dentro como fuera de sus hogares. Nos esforzamos por educar, capacitar y apoyar a las familias de perros, promoviendo una convivencia armoniosa y enriquecedora para todos.
        </span>
    } else {
        return <span className="philosophyItem__text">Forjar vínculos auténticos entre perros y sus cuidadores, basados en el amor, el respeto y la comprensión mutua.</span>
    }
}

function PhilosophyItem(props) {
    const {image, direction} = props;

    return (
        <div className="philosophyItem__wrapper" style={getStyle(direction)}>
            {getImg(direction)}
            <div className="philosophyItem__container" style={{textAlign: direction}}>
                <img className="philosophyItem__image" src={image} alt="philosophy"/>
                {/* {getTitle(direction)} */}
                {newGetTitle(direction)}
            </div>
        </div>
    );
}

export default PhilosophyItem;