import React from "react";
import "./AboutIntroduction.css";
import TeamArrow from "../../../assets/images/equipo_arrow.png";
import TeamArrow2 from "../../../assets/images/equipo_arrow2.png";

function AboutIntroduction() {
    return (
        <div className="aboutIntroduction__wrapper">
            <div className="aboutIntroduction__container">
                <img
                    src={TeamArrow}
                    className="aboutIntroduction__arrow"
                    alt="arrow"
                />
                <img
                    src={TeamArrow2}
                    className="aboutIntroduction__arrow--mobile"
                    alt="arrow"
                />
                <p className="aboutIntroduction__text">
                    <span>EL CANIL</span> es más que un simple proyecto, es una
                    iniciativa educativa diseñada para el beneficio tanto de los
                    perros como de los humanos. Surgió de años de experiencia
                    práctica trabajando con nuestros compañeros caninos, nacida
                    de una profunda observación: en muchos escenarios, las
                    necesidades de un perro a menudo superan la comprensión de
                    la familia, dificultando su capacidad para mejorar
                    conscientemente la calidad de vida del perro.
                    <br />
                    <br />
                    Nuestra propuesta reúne lo mejor de años de experiencia y
                    colaboración con los principales profesionales del campo
                    dentro de una <span>metodología amigable y adaptable.</span>
                    .<br />
                    <br />
                    Por eso es que conformamos un equipo multidisciplinario que
                    incluye desde Profesionales de la Etología, Veterinaria,
                    Nutrición y Paseadores.
                </p>
            </div>
            {/*<img className="aboutIntroduction__image" src={AboutMessageImage} alt="about_message"/>*/}
        </div>
    );
}

export default AboutIntroduction;
