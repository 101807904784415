import React from 'react';
import './EducationAlternatives.css';
import EducationAlternativeItem from "../../../components/EducationAlternativeItem/EducationAlternativeItem";
import EnhancedItem from "../../../components/EnhancedItem/EnhancedItem";
import EducationQuestionItem from "../../../components/EducationQuestionItem/EducationQuestionItem";
import Button from "../../../components/Button/Button";

function EducationAlternatives() {

    return (
        <div className="educationAlternatives__container">
            {/*<h2 className="educationAlternatives__title">*/}
            {/*    <span>ELECCIÓN DE</span> MODALIDAD DE CLASES*/}
            {/*</h2>*/}

            <div className="educationAlternatives__items__container">
                <EducationAlternativeItem
                    title="PRESENCIAL"
                    subtitle="Córdoba Capital, Argentina"
                    text="Clases orientadas y planificadas según las necesidades del alumno, se pueden realizar una vez por semana y tienen una duración de 60min."
                />
                <EducationAlternativeItem
                    title="ONLINE"
                    subtitle="Desde cualquier lugar del mundo"
                    text="Clases orientadas y planificadas según las necesidades del alumno, se pueden realizar una vez por semana y tienen una duración de 60min. Se utiliza la plataforma que sea más cómoda: Google MEET, Zoom, Skype o videollamada por WhatsApp."
                />
            </div>

            <EnhancedItem
                titleOrange="A SABER: la cantidad de packs/clases a contratar variará según el tratamiento sugerido al perro. Esto se coordina con previo diagnóstico y mientras se avanza en el esquema de trabajo."
                onClick={null}
            />
        </div>
    );
}

export default EducationAlternatives;