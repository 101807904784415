import React from "react";
import './VideoPlayer.css';
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const VideoPlayer = (props) => {
    const { url, title } = props;
    const { width } = useWindowDimensions();
    const getVideoHeight = () => {
        const aspectRatio = 16 / 9;
        const height = width / aspectRatio;
        return height;
    }

    return (
        <iframe
            width="100%"
            height={`${getVideoHeight()}px`}
            src={ url }
            title={ title }
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; mute;"
        />
    );
};
