import React from 'react';
import './PackItem.css';
import IconArrow from "../../assets/images/icon_arrow.png";

function PackItem(props) {
    const { title, text } = props;
    return (
        <div className="packItem__container">
            <div className="packItem__title__container">
                <img src={IconArrow} alt="arrow" className="packItem__arrow"/>
                <h2 className="packItem__title">
                    <span>PACK</span> {title}
                </h2>
            </div>
            <p className="packItem__text">{text}</p>
        </div>
    );
}

export default PackItem;