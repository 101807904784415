import React from 'react';
import './LandingEnhancedMenu.css';
import EnhancedItem from "../../../components/EnhancedItem/EnhancedItem";

const demoItems = [
    {
        titleOrange: "EDUCACIÓN CANINA",
        titleWhite: "PRESENCIAL",
        link: "/educacion-presencial"
    },
    {
        titleOrange: "EDUCACIÓN CANINA",
        titleWhite: "ONLINE",
        link: "/educacion-online"
    },
    {
        titleOrange: "PASEOS DE",
        titleWhite: "CALIDAD",
        link: "/paseos-calidad"
    },
    {
        titleOrange: "ACADEMIA",
        titleWhite: "",
        link: "/academia"
    },
    {
        titleOrange: "TIENDA",
        titleWhite: "",
        link: "/tienda"
    },
    {
        titleOrange: "BLOG",
        titleWhite: "",
        link: "/blog"
    },
    {
        titleOrange: "PODCAST",
        titleWhite: "",
        link: "/podcast"
    },
]

function LandingEnhancedMenu() {
    return (
        <div className="landing__enhancedMenu__container">
            {demoItems.map((item) => {
                return (
                    <EnhancedItem
                        titleOrange={item.titleOrange}
                        titleWhite={item.titleWhite}
                        onClick={() => console.log(item.link)}
                    />
                )
            })}
        </div>
    );
}

export default LandingEnhancedMenu;