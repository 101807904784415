import React from 'react';
import './EnhancedItem.css';
import IconArrow from '../../assets/images/icon_arrow.png';
import IconPlus from '../../assets/images/icon_plus.png';

function EnhancedItem(props) {
    const {titleOrange, titleWhite, onClick} = props;
    return (
        <div className="enhancedItem__container" onClick={onClick}>
            <img src={IconArrow} alt="arrow" className="enhancedItem__arrow"/>
            <span className={onClick ? "enhancedItem__title" : "enhancedItem__title--disabled"}>
                <span>{titleOrange}</span>{titleWhite}
            </span>
            <img src={IconPlus} alt="arrow" className="enhancedItem__plus"/>
        </div>
    );
}

export default EnhancedItem;