import React from 'react';
import './LandingTestimonial.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const TESTIMONIALS = [
    "No haber tenido problemas con tus perros anteriores, no significa que va a ser así con todos. Roma llegó y desde un inicio demostró 'tener carácter' , y con el correr de los meses se transformó en inmanejable a pesar de varios entrenadores. Y así conocí EL CANIL, y empezamos a trabajar en forma online, ya que estamos en distintas provincias. Rescato y resumo palabras como CONFIANZA y VÍNCULO, que considero fueron los pilares de nuestro trabajo. Hoy con Roma tenemos comunicación, disfrutamos de nuestros paseos y momentos de calma. INÉS, tutora de ROMA",
    "Terry fue rescatado por mí antes de que comience la Pandemia, por lo que todo su primer año de vida lo pasó adentro de casa, toda la estimulación tan necesaria de este momento de la vida de un perro fue nula. Físicamente creció bien, con todos sus controles al día. Pero emocionalmente no fue así. Llegó al punto de presentar conductas rozantes a la agresividad con personas ajenas a mi casa e hiperreactividad. Junto a EL CANIL hemos avanzado mucho y todavía queda camino por recorrer. Aprendí que la educación canina también va para los humanos, desde comenzar a entender qué le pasa a mi perro y porque se comporta así. La mayoría de las veces el problema radica en nosotros, y en no saber cómo ayudarlos. VIVIANA, tutora de TERRY",
    "Cuando Miguel llega a nuestras vidas, sabíamos que el principal problema era tratar la reactividad con otros perros, pues los paseos eran insostenibles. Acudimos a un adiestrador, y desde el desconocimiento, consideramos que las técnicas que nos habían enseñado como tirarle dos veces de la correa cuando veía otro perro estaban bien, Miguel fue (mal) diagnosticado con AGRESIVIDAD y llegó a usar hasta un collar eléctrico. Esto logró resultados rápidos pero terminó por romperlo por completo: incrementando su inseguridad y desconfianza. Por suerte conocimos a Macarena Fernández Mv. Etóloga, quién nos sugirió comenzar a trabajar con EL CANIL. Desde allí todo comenzó a mejorar, Miguel no era agresivo, padecía trastorno de ansiedad. Nosotros como tutores tuvimos que estudiar, aprender sobre lenguaje canino, aprender a entenderlo y desde ahí ayudarlo. Fue y es un trabajo diario que él aprenda a volver a confiar en los humanos pero es un perro excepcional y lo está haciendo muy bien. Hoy es un perro que hace vida de perro. ARANTXA y FEDERICO, tutores de MIGUEL",
    "Conocimos a EL CANIL por videos de Instagram donde resonamos con comportamientos que Denver también presentaba. Era imposible salir a la calle con él, tiraba de la correa, se escapaba, lloraba. Era nuestro primer perro, y creyendo que hacíamos las cosas bien, en realidad hacíamos todo mal producto de la desinformación. Comenzamos con clases online 100%, las cuales recomendamos muchísimo. Los resultados llegan, fue un período de trabajo largo pero hoy Denver es otro perro. Confíen siempre en sus perros que ellos saben más que nosotros. También recomendamos las jornadas de socialización para que aprendan en vivo cómo se comunican los perros. Gracias Diego y a todo el Equipo de EL CANIL. CAMI y CACU, tutores de DENVER",
];

function LandingTestimonial() {
    return (
        <div className="landingTestimonial__wrapper">
            <div className="landingTestimonial__container">
                <Splide options={ { rewind: true } }>
                    {TESTIMONIALS.map((product, index) => (
                        <SplideSlide key={index} style={{display: "flex", justifyContent: "center"}}>
                            <p className="landingTestimonial__text">{product}</p>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </div>
    );
}

export default LandingTestimonial;