import React from 'react';
import './WalkService.css';
import PackItem from "../../../components/PackItem/PackItem";
import DogOrange from '../../../assets/images/dog_orange.png';
import Button from "../../../components/Button/Button";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import EducationQuestionItem from "../../../components/EducationQuestionItem/EducationQuestionItem";
import {useNavigate} from "react-router-dom";

function WalkService() {
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const isMobile = width <= 500;
    const BUTTON_STYLE = isMobile ? {
        margin: "48px 0 0",
        alignSelf: "center",
        marginRight: "-10%",
        maxWidth: "85%"
    } : {margin: "48px 0 0", alignSelf: "center", marginRight: "-10%"};

    return (
        <>
            <div className="walkService__container">
                <h2 className="walkService__title">
                    <span>PASEOS DE</span> CALIDAD, RESPETUOSOS Y AMABLES
                </h2>

                <div className="walkService__description__container">
                    <iframe
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/ZXjUBkEmuqA?si=Zfi5YQNu1Anfw-f1&amp;controls=0"
                        title="Presentación el canil"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    />
                    <div className="walkService__description__content">
                        <h3 className="walkService__description__title">Córdoba Capital, Argentina</h3>
                        <p className="walkService__description__text">
                            ¿Sabías cuál es el momento del día que más espera nuestro
                            perro? <span>¡Salir a pasear!</span>
                            <br/>
                            <br/>
                            En EL CANIL consideramos que el paseo es mucho más que caminar y andar. Creemos que es parte
                            de
                            la nutrición mental de nuestros perros, por lo cual tratamos de brindar a los peludos un
                            espacio
                            donde verdaderamente puedan explorar, conocer el entorno, vincularse con otros perrunos y
                            ejercitar su nariz. De esta forma también brindamos un acompañamiento a la familia que ayude
                            a
                            mantener esta rutina tan sana y necesaria para sus vidas.
                        </p>
                        <p className="walkService__description__note">
                            Los paseos a coordinar son PERSONALIZADOS e INDIVIDUALES
                            o cómo máximo con algún amigo perruno de nuestra perro.
                        </p>
                    </div>
                </div>

                <div className="walkService__packs__container">
                    <img src={DogOrange} className="walkService__packs__image" alt="Perro naranja"/>
                    <PackItem title="CUATRO" text="Pensado para realizar un paseo semanal"/>
                    <PackItem title="OCHO"
                              text="Para comenzar a fomentar una rutina sana de paseos 2 veces por semana"/>
                    <PackItem title="DOCE" text="Ideal para perros muy activos, equivale a 3 paseos por semana"/>
                </div>


                {/*<div className="walkService__payment">*/}
                {/*    <h2 className="walkService__title walkService__title--payment">*/}
                {/*        <span>¿Cuáles son los</span> métodos de pago?*/}
                {/*    </h2>*/}
                {/*    <p className="walkService__description__text" style={{margin: "24px 0 0"}}>*/}
                {/*        Podés abonar por transferencia bancaria, mercado pago y tarjeta de crédito (tiene recargo del 10%).*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*<Button type="button" style={BUTTON_STYLE}>¡QUIERO MEJORAR LA CALIDAD DE VIDA DE MI PERRO!</Button>*/}
            </div>
            <div className="walkService__questions__container">
                <EducationQuestionItem
                    title="4"
                    text={<p className="educationQuestionItem__text">Los paseos duran <span>60 minutos.</span></p>}
                />
                <EducationQuestionItem
                    title="5"
                    text={<p className="educationQuestionItem__text">Socialización, desarrollo del olfato, mejora de la
                        concentración, y muchas ventajas más</p>}
                />
                <EducationQuestionItem
                    title="3"
                    text={<p className="educationQuestionItem__text">Podés abonar por transferencia bancaria, mercado
                        pago y tarjeta de crédito (tiene recargo del 10%).</p>}
                />
            </div>

            <div className="walkService__buttons__container">
                <div className="walkService__button__mobile">
                    <Button type="button" onClick={() => navigate("/", {state: {to: "contacto"}})}>¡QUIERO MEJORAR LA
                        CALIDAD DE VIDA CON MI PERRO!</Button>
                </div>
                <a href="https://drive.google.com/file/d/1RLGN-UfAnxtvxA4PkOnkbXj5J7eeyoir/view" download
                   target="_blank">
                    <Button type="button">
                        ¡QUIERO VER TODA LA INFO!
                    </Button>
                </a>
            </div>
        </>
    );
}

export default WalkService;