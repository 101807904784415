import React from 'react';
import "./Daycare.css"
import MainLayout from "../../layouts/MainLayout/MainLayout";
import DogThinking from "../../assets/images/thinking.gif";

function Daycare() {
    return (
        <MainLayout>
            <h2 className="daycare__title">
                GUARDERÍA Y HOSPEDAJE
            </h2>
            <div className="daycare__container">
                <img src={DogThinking} alt="dog thinking" className="daycare__image"/>
                <p className="daycare__text">¡Próximamente tendremos más información para vos!</p>
            </div>
        </MainLayout>
    );
}

export default Daycare;