import React from 'react';
import './WillLearnItem.css'

function WillLearnItem(props) {
    const {text, coloured} = props;
    return (
        <div className="willLearnItem__container">
            <p className={coloured ? "willLearnItem__text willLearnItem__text--orange" : "willLearnItem__text"}>{text}</p>
        </div>
    );
}

export default WillLearnItem;