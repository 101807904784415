import React from 'react';
import './Services.css';
import DiagnosisInterview from "../../features/Services/DiagnosisInterview/DiagnosisInterview";
import EducationAlternatives from "../../features/Services/EducationAlternatives/EducationAlternatives";
import DogWhiteRight from "../../assets/images/dog_white_right.png";
import WalkService from "../../features/Services/WalkService/WalkService";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import {useNavigate, useParams} from "react-router-dom";
import InterviewArrow from "../../assets/images/entrevista_arrow.png";
import AlternativesArrow from "../../assets/images/modalidad_arrow.png";
import EducationQuestionItem from "../../components/EducationQuestionItem/EducationQuestionItem";
import Button from "../../components/Button/Button";

function Services() {
    const {tipo} = useParams();
    const navigate = useNavigate();

    // Be careful, duplicated code
    return (
        <MainLayout>
            {tipo === "todos" && (
                <>
                    <section className="services__diagnosis__wrapper">
                        <img src={InterviewArrow} className="services__diagnosis__arrow" alt="entrevista_disagnostico"/>
                        <div className="services__diagnosis">
                            <DiagnosisInterview/>
                            <img className="services__diagnosis__image" src={DogWhiteRight} alt="Dog"/>
                        </div>
                    </section>

                    <section className="services__alternatives">
                        <EducationAlternatives/>
                    </section>
                    <section className="services__walks">
                        <WalkService/>
                    </section>
                </>
            )}
            {tipo === "educacion" && (
                <>
                    <section className="services__diagnosis__wrapper">
                        <img src={InterviewArrow} className="services__diagnosis__arrow" alt="entrevista_disagnostico"/>
                        <div className="services__diagnosis">
                            <DiagnosisInterview/>
                            <img className="services__diagnosis__image" src={DogWhiteRight} alt="Dog"/>
                        </div>
                    </section>

                    <section className="services__diagnosis__wrapper">
                        <img src={AlternativesArrow} className="services__diagnosis__arrow--alternatives" alt="modalidades"/>
                        <section className="services__alternatives">
                            <EducationAlternatives/>
                        </section>
                    </section>
                    <section className="services__educationAlternatives">
                        <div className="educationAlternatives__questions__container">
                            <EducationQuestionItem
                                title="1"
                                text={<p className="educationQuestionItem__text">Únicamente <span>voluntad por querer entender a tu perro.</span></p>}
                            />
                            <EducationQuestionItem
                                title="2"
                                text={<p className="educationQuestionItem__text">El verdadero secreto de todo es la <span>paciencia.</span></p>}
                            />
                            <EducationQuestionItem
                                title="3"
                                text={<p className="educationQuestionItem__text"><span>Desde Argentina:</span> podés abonar por transferencia bancaria, mercado pago y tarjeta de crédito (tiene recargo del 10%). <br/><span>Desde el Exterior:</span> podés abonar a través de PayPal.</p>}
                            />
                        </div>

                        <div className="educationAlternatives__buttons__container">
                            <Button type="button" onClick={() => navigate("/", {state: {to: "contacto"}})}>¡QUIERO MEJORAR LA CALIDAD DE VIDA CON MI PERRO!</Button>
                            <a href="https://drive.google.com/file/d/1RLGN-UfAnxtvxA4PkOnkbXj5J7eeyoir/view" download target="_blank">
                                <Button type="button">
                                    ¡QUIERO VER TODA LA INFO!
                                </Button>
                            </a>
                        </div>
                    </section>
                </>
            )}

            {tipo === "paseos" && (
                <section className="services__walks">
                    <WalkService/>
                </section>
            )}

        </MainLayout>
    );
}

export default Services;