import React from 'react';
import './AboutWillLearn.css';
import WillLearnItem from "../../../components/WillLearnItem/WillLearnItem";
import QueAprenderas from "../../../assets/images/que_aprenderas.png"
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';

const willLearnItems = [
    "CALMA",
    "INTERPRETACIÓN",
    "TOLERANCIA",
    "COOPERACIÓN",
]

function AboutWillLearn() {

    return (
        <div className="aboutWillLearn__wrapper">
            <img className='aboutWillLearn__image' src={QueAprenderas} alt='que_aprenderas'/>
            <div className="aboutWillLearn__container">
                <Splide options={{
                    rewind: true,
                    pagination: false,
                    autoplay: true,
                    pauseOnHover: false
                }}>
                    {willLearnItems.map((item, index) => {
                        return (
                            <SplideSlide key={index} style={{display: "flex", justifyContent: "center"}}>
                                <WillLearnItem
                                    text={item}
                                    coloured={index % 2 === 0}
                                />
                            </SplideSlide>
                        )
                    })}
                </Splide>

            </div>
        </div>
    );
}

export default AboutWillLearn;