import React from 'react';
import './Academy.css';
import BrochureDownload from "../../components/BrochureDownload/BrochureDownload";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import IconArrow from "../../assets/images/icon_arrow.png";

function Academy() {
    return (
        <MainLayout>
            <h2 className="academy__title">
                ACADEMIA
            </h2>
            <div className="academy__row">
                <img src={IconArrow} alt="arrow" className="academy__icon"/>
                <h3 className="academy__subtitle">CURSOS Y SEMINARIOS</h3>
            </div>
            <section className="academy__brochures">
                <BrochureDownload/>
                <BrochureDownload/>
                <BrochureDownload/>
            </section>
        </MainLayout>
    );
}

export default Academy;