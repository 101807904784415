import React from 'react';
import './EducationQuestionItem.css';
import WhatDoYouNeed from "../../assets/images/quenecesitas_text.png";
import KeyToWork from "../../assets/images/claves_text.png";
import PaymentMethods from "../../assets/images/pagos_text.png";
import WalkDuration from "../../assets/images/duracionpaseo_text.png";
import WalkBenefits from "../../assets/images/beneficiospaseo_text.png";

const getTitleImage = (title) => {
    switch (title) {
        case "1":
            return <img className="educationQuestionItem__title--1" src={WhatDoYouNeed} alt="que_necesitas"/>
        case "2":
            return <img className="educationQuestionItem__title--2" src={KeyToWork} alt="claves"/>
        case "3":
            return <img className="educationQuestionItem__title--3" src={PaymentMethods} alt="pagos"/>
        case "4":
            return <img className="educationQuestionItem__title--4" src={WalkDuration} alt="duracion_paseo"/>
        case "5":
            return <img className="educationQuestionItem__title--5" src={WalkBenefits} alt="benficios_paseo"/>
    }
}

function EducationQuestionItem(props) {
    const { title, text } = props;
    return (
        <div className="educationQuestionItem__container">
            {/*<h4 className="educationQuestionItem__title">{title}</h4>*/}
            {getTitleImage(title)}
            {text}
        </div>
    );
}

export default EducationQuestionItem;