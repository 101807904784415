import React from 'react';
import "./Blog.css"
import MainLayout from "../../layouts/MainLayout/MainLayout";
import {VideoPlayer} from "../../components/VideoPlayer/VideoPlayer";

function Blog() {
    return (
        <MainLayout>
            <h2 className="blog__title">
                BLOG
            </h2>
            <div className="blog__container">
                <VideoPlayer
                    url="https://canil-bucket.s3.amazonaws.com/videos/blog_compressed.mp4"
                    title="Blog el canil"
                />
            </div>
        </MainLayout>
    );
}

export default Blog;