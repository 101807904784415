import React, {useState} from "react";
import "./Header.css";
import Logo from "../../assets/images/logo_white.png"
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {IconMenu2} from '@tabler/icons-react';

export const Header = () => {
    const navigate = useNavigate();
    const {width} = useWindowDimensions();
    const isMobile = width <= 768;
    const [displayNavBar, setDisplayNavBar] = useState(false);
    const [displayServices, setDisplayServices] = useState(false);
    const [displayAbout, setDisplayAbout] = useState(false);
    const [displayStore, setDisplayStore] = useState(false);

    const handleNavigation = (e) => {
        setDisplayNavBar(false)
        switch (e) {
            case "nosotros":
                navigate("/nosotros");
                break;
            case "blog":
                navigate("/blog");
                break;
            case "podcast":
                navigate("/podcast");
                break;
            case "equipo":
                setDisplayServices(false)
                setDisplayStore(false)
                navigate("/nosotros", {state: {to: "equipo"}});
                break;
            case "mision":
                setDisplayServices(false)
                setDisplayStore(false)
                navigate("/nosotros", {state: {to: "filosofia"}});
                break;
            case "academia":
                setDisplayServices(false)
                setDisplayStore(false)
                navigate("/academia");
                break;
            case "guarderia":
                setDisplayServices(false)
                setDisplayStore(false)
                navigate("/guarderia");
                break;
            case "servicios":
                navigate("/servicios/todos");
                break;
            case "educacion":
                setDisplayServices(false)
                setDisplayStore(false)
                navigate("/servicios/educacion");
                break;
            case "paseos":
                setDisplayServices(false)
                setDisplayStore(false)
                navigate("/servicios/paseos");
                break;
            case "tienda":
                navigate("/tienda");
                break;
            case "minorista":
                navigate("/tienda", {state: {type: "minorista"}});
                break;
            case "mayorista":
                navigate("/tienda", {state: {type: "mayorista"}});
                break;
            case "contacto":
                navigate("/", {state: {to: "contacto"}});
                break;
            case "inicio":
                navigate("/");
                break;
            default:
                navigate("/");
                break;
        }
    }

    return (
        <div className="header__wrapper">
            <div className="header__container">
                <img onClick={() => handleNavigation()} className="header__logo" src={Logo} alt="ElCanil"/>


                {isMobile ? (
                    <IconMenu2 className="header__menu__icon" size={32} color="white" stroke={1.5}
                               onClick={() => setDisplayNavBar(!displayNavBar)}/>
                ) : (
                    <>
                        <div className="header__navigation">
                            <span
                                onClick={() => handleNavigation('inicio')}
                                className="header__navigation__item"
                            >
                                INICIO
                            </span>
                            <span
                                onClick={() => {
                                    setDisplayServices(false)
                                    setDisplayStore(false)
                                    setDisplayAbout(!displayAbout)
                                }}
                                className="header__navigation__item"
                            >
                                NOSOTROS
                            </span>
                            <span
                                onClick={() => {
                                    setDisplayAbout(false)
                                    setDisplayStore(false)
                                    setDisplayServices(!displayServices)
                                }}
                                className="header__navigation__item"
                            >
                                SERVICIOS
                            </span>
                            <span
                                onClick={() => {
                                    setDisplayAbout(false)
                                    setDisplayServices(false)
                                    setDisplayStore(!displayStore)
                                }}
                                className="header__navigation__item"
                            >
                                TIENDA
                            </span>
                            <span
                                onClick={() => handleNavigation('blog')}
                                className="header__navigation__item"
                            >
                                BLOG
                            </span>
                            <span
                                onClick={() => handleNavigation('podcast')}
                                className="header__navigation__item"
                            >
                                PODCAST
                            </span>
                            <span
                                onClick={() => handleNavigation('contacto')}
                                className="header__navigation__item"
                            >
                                CONTACTO
                            </span>
                        </div>
                        {displayServices && (
                            <div className="header__navigation__menu">
                            <span onClick={() => handleNavigation('educacion')}
                                  className="header__navigation__item--submenu">EDUCACIÓN CANINA</span>
                                <span onClick={() => handleNavigation('paseos')}
                                      className="header__navigation__item--submenu">PASEOS DE CALIDAD</span>
                                <span onClick={() => handleNavigation('academia')}
                                      className="header__navigation__item--submenu">ACADEMIA</span>
{/*                                 <span onClick={() => handleNavigation('guarderia')}
                                      className="header__navigation__item--submenu">HOSPEDAJE Y GUARDERÍA</span> */}
                            </div>
                        )}
                        {displayAbout && (
                            <div className="header__navigation__menu header__navigation__menu--about">
                            <span onClick={() => handleNavigation('equipo')}
                                  className="header__navigation__item--submenu">EQUIPO</span>
                                <span onClick={() => handleNavigation('mision')}
                                      className="header__navigation__item--submenu">MISIÓN / VISIÓN / CÓMO TRABAJO</span>
                            </div>
                        )}
                        {displayStore && (
                            <div className="header__navigation__menu header__navigation__menu--store">
                            <span onClick={() => handleNavigation('minorista')}
                                  className="header__navigation__item--submenu">VENTA MINORISTA</span>
                                <span onClick={() => handleNavigation('mayorista')}
                                      className="header__navigation__item--submenu">VENTA MAYORISTA</span>
                            </div>
                        )}
                    </>
                )}

                {displayNavBar && (
                    <div className="header__navigation__mobile">
                        <span onClick={() => handleNavigation('inicio')}
                              className="header__navigation__item--mobile">INICIO</span>
                        <span onClick={() => {
                            setDisplayServices(false)
                            setDisplayStore(false)
                            setDisplayAbout(!displayAbout)
                        }}
                              className="header__navigation__item--mobile">NOSOTROS</span>
                        {displayAbout && (
                            <div className="header__navigation__menu--mobile">
                            <span onClick={() => handleNavigation('equipo')}
                                  className="header__navigation__item--submenu--mobile">EQUIPO</span>
                                <span onClick={() => handleNavigation('mision')}
                                      className="header__navigation__item--submenu--mobile">MISIÓN / VISIÓN / CÓMO TRABAJO</span>
                            </div>
                        )}
                        <span onClick={() => {
                            setDisplayAbout(false)
                            setDisplayStore(false)
                            setDisplayServices(!displayServices)
                        }}
                              className="header__navigation__item--mobile">SERVICIOS</span>
                        {displayServices && (
                            <div className="header__navigation__menu--mobile">
                            <span onClick={() => handleNavigation('educacion')}
                                  className="header__navigation__item--submenu--mobile">EDUCACIÓN CANINA</span>
                                <span onClick={() => handleNavigation('paseos')}
                                      className="header__navigation__item--submenu--mobile">PASEOS DE CALIDAD</span>
                                <span onClick={() => handleNavigation('academia')}
                                      className="header__navigation__item--submenu--mobile">ACADEMIA</span>
                            </div>
                        )}
                        <span onClick={() => {
                            setDisplayAbout(false)
                            setDisplayServices(false)
                            setDisplayStore(!displayStore)
                        }}
                              className="header__navigation__item--mobile">TIENDA</span>
                        {displayStore && (
                            <div className="header__navigation__menu--mobile">
                            <span onClick={() => handleNavigation('minorista')}
                                  className="header__navigation__item--submenu--mobile">VENTA MINORISTA</span>
                                <span onClick={() => handleNavigation('mayorista')}
                                      className="header__navigation__item--submenu--mobile">VENTA MAYORISTA</span>
                            </div>
                        )}
                        <span onClick={() => handleNavigation('blog')}
                              className="header__navigation__item--mobile">BLOG</span>
                        <span onClick={() => handleNavigation('podcast')}
                              className="header__navigation__item--mobile">PODCAST</span>
                        <span onClick={() => handleNavigation('contacto')}
                              className="header__navigation__item--mobile">CONTACTO</span>
                    </div>
                )}
            </div>
        </div>
    )
};
