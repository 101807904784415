import React from 'react';
import './MainLayout.css'
import {Header} from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function MainLayout({children}) {
    return (
        <div className="mainLayout__container">
            <Header/>
            <main>
                {children}
            </main>
            <Footer/>
        </div>
    );
}

export default MainLayout;